.topbar
{
    display: flex;
    flex-wrap: wrap;

    margin-bottom: 1em;

    justify-content: space-between;
}

.topbar__logo
{
    position: relative;
    top: 4px;
    left: -1em;
}

.header--landing .main-nav ul {
    color: $white;
}

.header--inner .main-nav ul {
    color: $red;
}

.main-nav ul
{
    display: flex;
    flex-direction: row;
    margin-left: 9px;
    
    font-size: 0.75em;
    font-weight: 500;

    list-style-type: none;

    text-align: center;
    text-transform: uppercase;

}

.main-nav a
{
    display: block;

    padding: 10px;

    text-decoration: none;

    color: inherit;
}


.header--landing
{
    color: $white;
    background-image: $linear-gradient__orange-red-pink;
}

.header--inner
{
    padding-bottom: 10px;

    color: $red;
}

.hero
{
    overflow: hidden;
}

.hero--warm
{
    background-image: $linear-gradient__orange-red-pink;
}

.header--landing .cta
{
    color: $red;
    background-color: $white;
}

.custom-active{

    color: #FF1A4D !important;
    padding-bottom: 2px !important; 
    border-bottom: solid 2px #FF1A4D !important;

}

.nav-link:hover{
    color: #FF1A4D !important;
}



.dropbtn {
    color: rgba(0,0,0,.55);
    border: none;
}

.drop {
    position: relative;
    display: inline-block;
}

.dropdown-c {
    display: none;
    position: absolute;
    min-width: 350px;
    border-radius: 5px;
    border: 1px solid rgba(154,154,154,0.4);
    z-index: 1;
    padding-top: 10px;
    padding-bottom: 10px;
    
   
}

.grid-content-drop{
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
} 


.dropdown-c a {
    color: black;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;

    .bottom-nav{
        font-size: 13px;
    }
}

.dropdown-c .item-drop:hover {
    background-color: #f6f6f6 ;
    color: black;}

.drop:hover .dropdown-c {

    display: flex;
    flex-direction: column;
    color: black;
    z-index: 99;
}
@media screen and (min-width: 993px) {
    .grid-content-drop{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    } 
    .dropdown-c {
        left: -60px;
        min-width: 600px;
    }

   
}
@media screen and (min-width: 1490px) {
    .dropdown-c {
        
        min-width: 600px;
        a{
            display: flex;
            flex-direction: row;
            text-align: start;
            gap: 15px;
            .nav-icon{
                width: 80px;
            }
            .bottom-nav{
                font-size: 13px;
            }
        }
        
    }

    
    
        

   
}


// WHEN HERO CONTAINS ISO-CARDS:

.hero .iso-cards
{
    display: flex;
}
