.cta,
form button
{
    font-size: .66em;
    font-weight: 500;

    display: inline-block;

    padding: .66em 2em;

    transition: .2s all;
    transform: translateY(0);
    text-decoration: none;
    letter-spacing: .8px;
    text-transform: uppercase;

    border-radius: 3px;
    box-shadow: $button-boxshadow;
}

.cta:hover
{
    transform: translateY(-1px);

    box-shadow: $button-boxshadow-elevated;
}

.cta:active
{
    transform: translateY(1px);

    box-shadow: $button-boxshadow-elevated;
}

.read-more
{
    font-size: .8em;
    font-weight: 500;

    display: inline-block;

    margin-top: 1.5em;
    padding-right: 3em;

    transition: .2s all;
    text-decoration: none;

    color: inherit;
    background-image: url('../img/right-arrow.svg');
    background-repeat: no-repeat;
    background-position: 98%;
}

.read-more:hover
{
    background-position: 100%;
}
