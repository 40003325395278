body>footer
{
    padding-top: 2em;

    color: #fff;
    background-color: #2d3142;
}

body>footer
{
    padding: 2em;
}

body>footer a
{
    font-size: .75em;
}

body>footer img
{
    max-height: 40px;
}


footer {
    span {
        font-size: 1rem;
        color: #dadada;
        display: block;
        margin-bottom: 5px;
    }

    .divider {
        border-top: 1px solid #6c757d;
    }

    .footer-links {
        h6 {
            text-transform: uppercase;
            font-size: 0.8rem;
            color: #6c757d;
            margin-bottom: 5px;
        }

        a {
            display: block;
            text-decoration: none;
            color: #dadada;
            font-size: 1rem;
            margin-bottom: 5px;
            margin-top: 0;
            font-weight: 300;
        }
    }

    .icons a{
        font-size: 1.5rem;
        margin: 0;
    }
}
