label,
input
{
    display: block;
}

button
{
    display: inline-block;

    border: none;
    background-color: #fff;
}

label
{
    font-size: .66em;
    font-weight: 500;

    margin-bottom: .5em;

    letter-spacing: .5px;
    text-transform: uppercase;
}

input
{
    font-family: Montserrat, sans-serif;
    font-size: 1rem;

    width: 100%;
    margin-bottom: 1.5em;
    padding: .75em;

    border: none;
    border-radius: 2px;
    background: #fff;
    box-shadow: inset 0 1px 2px 0 rgba(0,0,0,.50);
}
