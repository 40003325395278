
//Layout Trainings
.training, .training3, .training4, .training2 {
    height: 60px;
    width: 60px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
}

.icon-diagnosis, .icon-diagnosis2, .icon-diagnosis3, .icon-diagnosis4 {
    padding: 10px;
    min-width: auto;
}



.autentic{
    min-width: 120px;
}
.training3{
    min-width: 60px;
}

.icon-benefits{
    max-width: 60px;
}
.button-book{
    background-color:  #FFFFFF !important;
    color:#3B5DEF !important;
    width: 190px !important;

}

.line-inner{
    border-top: 1px solid white;
}

.trainings-covers{
    border-radius: 10px;
    box-shadow: 0 2px 14px 0 rgba(0,0,0,0.1);
  
} 

.covers{
    border-bottom: 1px solid #dee2e6 !important;
}

@media screen and (min-width: 760px) {
    .col-md-4{
        width: 32.3% !important;
    }
    .covers{
        border-right: 1px solid #dee2e6 !important;
        border-bottom: 0px solid #dee2e6 !important;
    }
   
    
}
@media screen and (min-width: 956px) {
   .cards-button{
    width: 48.6% !important;
   }
}
@media screen and (min-width: 1220px) {
    .cards-button{
     width: 24.1% !important;
    }
 }





.line-height{
    line-height: 1;
}
