.top-home {
    width: 900px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.blue-section {
    background-color: #3B5DEF;

    .cards-button {
        background-color: rgba(255, 255, 255, 0.2) !important;

    }
}

.training-certification-cards {
    background-color: $white;
    border-radius: 15px;

    h2 {
        font-size: 1.5rem;
        font-weight: bold;
        text-transform: uppercase;
    }

    h3 {
        font-size: 1.1rem;
        font-weight: 600;
        margin: 0;
        display: inline;
    }

    p {
        font-size: 1.1rem;
        margin: 0;
    }

    .list {
        list-style-type: none;
        padding: 0;

        li {
            margin: 10px 0px;

            p {
                display: inline;
            }
        }
    }

}

.question {

    button {
        background-color: transparent;
        border: 0;
    }

    .answer {
        font-weight: 300;
        font-size: 1rem;
        height: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;

        a {
            color: #FF0058;
            text-decoration: none;

            &:hover {
                color: #FF0058;
            }
        }
    }
}

.question.active {
    h5 {
        color: #3B5DEF;
    }

    .answer {
        padding-top: 1rem;
        height: auto;
    }
}

.email-modal {
    background: rgba(43, 51, 62, 0.8);
    backdrop-filter: blur(2px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1060;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &.show {
        display: flex;
    }

    .content {
        max-width: 550px;
    }

    .body {
        border-radius: 15px;
        box-shadow: 0px 100px 200px rgba(52, 64, 84, 0.18);
    }

    .email-form {
        input {
            box-shadow: none;
            border: 1px solid #d3d3d3;
            border-radius: 10px;
        }

    }
}