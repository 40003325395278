@media screen and (min-width: 300px) {
    .content--secondary .cta
    {
        width: 260px;
    }
   
    
    .contact-section, .banner-home{
        
        background-position-y: 50%;
    
    }
}

@media screen and (min-width: 470px) {
html
    {
        font-size: 20px;
    }

    

}

@media screen and (min-width: 520px)
{

    .header
    {
        text-align: center;
    }

    .header--landing
    {
        padding-bottom: 4em;
    }

    .topbar
    {
        margin-bottom: 3.5em;
    }

    .headline
    {
        text-align: center;
    }

    .headline__sub
    {
        display: block;
        margin-top: 1rem;
    }

    .content p
    {
        text-align: justify;
    }

}

@media screen and (max-width: 670px)
{
    .hero .iso-card
    {
        width: 150px;
        height: 100px;
        margin: 0 calc(-80px + 10%);
    }
}

@media screen and (min-width: 671px)
{
    .header--landing
    {
        padding-bottom: 8em;
    }

    .header--landing + .iso-cards
    {
        position: relative;

        display: flex;

        margin-top: -160px;
        margin-bottom: 1rem;
        padding-left: 3%;
    }
}


@media screen and (min-width: 768px){
    .mid-input {
        max-width: 400px;
    }
    

}

@media screen and (max-width: 868px){

    .training-mid-section{
        max-width: 60px;
    }

    .contact-section , .banner-home{
        
        background-position-y:bottom;
    
    }
   
}

@media screen and (max-width: 920px){
    .banner-home{
        background-position-y: bottom;
    }
}
@media screen and (min-width: 921px){
    .banner-home{
        background-position-y: 0;
    }
}
@media screen and (max-width: 991px){

    .nav-item{
       display: flex !important;

    }
    .navbar-nav{
        margin-left: 20px !important;
    }
    

}
@media screen and (min-width: 1419px){

    .contact-section{
        
        background-position-y: 0;
    
    }
   
}