.bg-overlay
{
    position: relative;
}

.bg-overlay::before
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    content: '';
    pointer-events: none;

    opacity: .1;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-overlay--ra::before
{
    background-image: url('../img/background-ra.svg');
}

.bg-overlay--ev::before
{
    background-image: url('../img/background-ev.svg');
}

.bg-overlay--blue-gradient::before
{
    opacity: .9;
    background-image: linear-gradient(-70deg, #4ba3ff 5%, rgba(50,76,255,.90) 100%);
}
