.modal
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;

    padding: 2em;

    transition: .5s all;

    opacity: 1;
    background-color: rgba(0,0,0,.8);

    justify-content: center;
    align-items: center;
}

.modal__container {
    position: absolute;
    top: 20px;
    width: 80%;
}

.modal.modal--is-hidden
{
    pointer-events: none;

    opacity: 0;
}

.modal__close-modal
{
    font-size: .5em;

    display: inline-block;
    padding: 5px;
    border-radius: 2px;
    float: right;

    text-decoration: none;
    letter-spacing: .5px;
    text-transform: uppercase;
}
