@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700&display=swap');

html {
    font-size: 16px !important;
}

* {
    font-family: 'Poppins', sans-serif !important;
}

.title {
    size: 3.125rem;
}

.subtitle {
    size: 1.25rem;
    font-weight: 700;
}

.big-text {
    font-size: 1.375rem;
}

.text{
    font-size: 1.125rem !important;
}

.small-text {
    font-size: 1rem;
}

.link {
    font-size: 1.125rem;
    text-decoration: none;
}

.bold {
    font-weight: 700;
}

.semibold {
    font-weight: 600;
}

.light {
    font-weight: 300 !important;
}

.uppercase {
    text-transform: uppercase;
}

.post-font {
    font-family: Merriweather,serif;
    font-size: 17px;
    line-height: 32px;
    color: #232527;
}