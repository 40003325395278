.content a,
footer a
{
    color: inherit;
}

.content--primary .read-more
{
    color: #f4793e;
    background-image: url("../img/right-arrow-dark.svg");
}

.content--secondary .read-more,
.blog-header .read-more
{
    color: #6bf175;
    background-image: url("../img/right-arrow-light.svg");
}

.content .section-token
{
    display: block;

    max-width: 3rem;
    margin: 0 auto 1.5em auto;
}

.content--primary .section-header
{
    color: #ff1a4d;
}

.content--secondary
{
    padding-top: 3em;

    color: #fff;
    background-image: linear-gradient(146deg, rgba(70,94,255,1) 0%, rgba(74,163,255,1) 100%);
}

.content--secondary .cta {
    margin: 1em auto;
    background-color: $red;
    display: block;
    text-align: center;
}

.content--cta
{
    padding-top: 2em;

    color: #fff;
    background-color: #2d3142;
}

.content__subsection
{
    display: flex;
    flex-direction: column;
}

// WHEN CONTENT CONTAINS ISO-CARDS:

.content__subsection .iso-card
{
    max-width: 50%;
    height: auto;
    margin: 1em auto;
}
