.testimonial
{
	padding: 1em;
	line-height: 1.6;
	background-image: url("../img/big-quote.svg");
	background-repeat: no-repeat;
	background-position: top left;

	@media screen and (max-width: 480px) {
		margin: 1em;
	}
}

.testimonial>footer
{
	text-transform: uppercase;
	font-size: 0.7em;
	margin-top: 1em;
	color: $lightgray;

}