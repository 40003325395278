.events-list
{
    display: flex;

    margin-top: 1.5em;

    list-style-type: none;

    flex-wrap: wrap;
    justify-content: space-between;
}

.events-list__event
{
    margin-bottom: 1.5em;
}

.events-list__event .event-date,
.events-list__event .event-title,
.events-list__event .event-location
{
    display: block;
}

.events-list__event .event-date
{
    font-size: .66em;

    margin-bottom: .5em;
}

.events-list__event .event-title
{
    font-size: .9em;

    margin-bottom: .2em;
}

.events-list__event .event-location
{
    font-size: .66em;
    font-weight: 300;
}

.token-list
{
    list-style-type: none;
}

.token-list__item
{
    font-size: 0.9em;

    display: flex;

    margin: 1em 0;

    align-items: center;
}

.token-list__item p
{
    margin: 0;
    margin-left: 2em;
}

.token-list__item img
{
    width: 40px;
    height: 40px;
}
