.certification-card {
    background-color: $white;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 0;
    margin-bottom: 20px;
    transition: all 0.3s;
    max-width: 400px;

    &:hover {
        box-shadow: 2px 2px 20px rgba(96, 114, 190, 0.3);
    }

    .certification-card-footer {
        display: block;
        padding: 1.2rem;
        margin-top: 1rem;
        text-align: center;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        background-color: $pink;
        font-size: 17px;
        color: $white;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: 600;
        letter-spacing: 0.5px;
        line-height: 17px;
        background-color: $pink;
    }
}

.certification-card-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: $darkgray;

    h5,
    a {
        font-size: 18px;
        line-height: 27px;
        font-weight: 600;
        text-decoration: none;
    }
}

.team-trainings-card {
    background-color: $blue;
    border-radius: 10px;
    background-image: url("/images/certification_card_background.png");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    max-width: 400px;

    h5 {
        font-size: 18px;
        line-height: 27px;
        font-weight: 600;
        text-decoration: none;
    }
}

.certification-training-card-footer {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    a {
        font-size: 16px;
        line-height: 17px;
        font-weight: 600;
    }
}

.certification-margin-bottom {
    margin-bottom: 9.3rem;
}

.certification-clients-saying-marging-top {
    margin-top: -270px
}

.img-certification {
    max-width: 414px;
    height: auto;
}

.dates-card {
    background-color: #FFF;
    border-radius: 10px;
    border: 1px solid #D0D5DD;
    transition: all 0.3s;

    &:hover {
        box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.5);
    }
}

@media (max-width: 1197px) {
    .img-certification {
        max-width: 414px;
    }
}


@media (max-width: 500px) {
    .img-certification {
        max-width: 100%;
    }
}