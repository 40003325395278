//trainings

.training-img{
    max-width: 534px;
}
.card{
    border-radius: 20px !important;
    max-width: 310px !important;
}
.card-header{
    border-top-left-radius: 20px !important;
    border-top-right-radius:20px !important;
    p{
        font-size: 14px !important;
    }
}
.card-text{
    font-size: 15px !important;
}


.training-mid-section{
    max-width: 80px;
}
.mid-section p{
    font-size: 13px;
}
